<script>
import BasePlayerBasic from '@app/design-system/base/player/player-basic.vue'
import BaseTitleSection from '@app/design-system/base/title/title-section.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HomeBecomeTutor',
  components: { BaseTitleSection, BasePlayerBasic },
  computed: {
    ...mapGetters('local', ['getStartTeaching']),
    title () {
      return 'Become a tutor at Ostado'
    },
    item () {
      return this.getStartTeaching
    }
  }
}
</script>

<template>
  <section>
    <BaseTitleSection :title="title" class="mb-6 lg:mb-8" />
    <div class="become-tutor">
      <div class="become-tutor__main">
        <div class="w-full lg:w-1/3 order-2 lg:order-1 text-center lg:text-left">
          <BaseIcon name="format_quote" size="2xl" color="black" class="mt-8 lg:mt-0" />
          <p class="become-tutor__title">
            {{ item.feedback }}
          </p>
          <BaseAvatarTeacher
            :img-url="item.profile"
            :title="item.fullname"
            :subtitle="item.job_title"
            mode="start-teaching-testimonial"
            class="mt-8 justify-center lg:justify-start"
          />
          <BaseButton
            :label="$t('actions.become_tutor')"
            class="w-full lg:w-[265px] mt-8 lg:mt-16"
            icon-left-custom="teacher"
            light
            tag="a"
            size="md-lg"
            :to="$links.absolute($nuxt, 'start_teaching')"
          />
        </div>
        <div class="w-full lg:w-2/3 order-1 lg:order-2">
          <div class="w-full rounded-2xl">
            <BasePlayerBasic
              classes=""
              :src="item.video"
              :cover="item.video_cover"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.become-tutor {
  @apply container;
  &__main {
    @apply flex flex-col lg:flex-row items-center border border-primary-100 rounded-2xl p-4 mb-12
    lg:space-x-12;
  }
  &__title {
    @apply text__body-1-xs--regular lg:text__headline-4-md--regular mt-2 text-title;
  }
}
</style>
